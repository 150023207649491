import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useLocation } from 'react-router';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { CREATE_DISHES_NAVIGATION_TABS } from '../constants';

const EditDishesTabs = () => {
  const location = useLocation();

  const EDIT_DISH_TABS: IVerticalTabItem[] = [
    {
      id: CREATE_DISHES_NAVIGATION_TABS.BASIC_DETAILS,
      label: 'Basic Details',
      Icon: RestaurantIcon,
      path: `${location.pathname}#basic-details`,
    },
    {
      id: CREATE_DISHES_NAVIGATION_TABS.CHOICES,
      label: 'Choices',
      Icon: RestaurantIcon,
      path: `${location.pathname}#choices`,
    },
    {
      id: CREATE_DISHES_NAVIGATION_TABS.ADDONS,
      label: 'Addons',
      Icon: RestaurantIcon,
      path: `${location.pathname}#addons`,
    },
    {
      id: CREATE_DISHES_NAVIGATION_TABS.UPLOAD_IMAGES,
      label: 'Upload Images',
      Icon: RestaurantIcon,
      path: `${location.pathname}#upload-images`,
    },
  ];

  const { currentTab, handleTabChange } = useTabNavigation(
    EDIT_DISH_TABS,
    CREATE_DISHES_NAVIGATION_TABS.BASIC_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={EDIT_DISH_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default EditDishesTabs;
