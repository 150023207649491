import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { SelectProps } from '@mui/material';
import truncate from 'lodash/truncate';
import { memo, useCallback, useEffect } from 'react';

import AppSelector from '../../../components/AppSelector';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectSubCategories } from '../../../redux/selectors/subCategoriesSelectors';
import { Colors } from '../../../theme/colors';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { fetchSubCategoriesData } from '../redux/subCategoriesSlice';

type Props = Omit<SelectProps, 'error'> & {
  error?: string;
};

const DishSubCategoriesSelector = memo((props: Props) => {
  const dispatch = useAppDispatch();
  const { subCategoriesData, refreshing } = useAppSelector(selectSubCategories);

  const fetchSubCategoriesDataCallback = useCallback(async () => {
    dispatch(setMenuPaginationSkip(undefined));
    dispatch(setMenuPaginationLimit(undefined));
    dispatch(setMenuPaginationSearchKey(''));
    await dispatch(fetchSubCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    fetchSubCategoriesDataCallback();
  }, [fetchSubCategoriesDataCallback]);

  return (
    <AppSelector
      disabled={refreshing}
      showOptional
      label="Sub Categories"
      placeholder="Sub Categories"
      name="subCategories"
      multiple
      onChange={props.onChange}
      onBlur={props.onBlur}
      error={props.error}
      value={props.value}
      data-testid="subCategories"
      displayEmpty
      renderSelectedArray={(selected) => {
        if (selected.length === 0) {
          return <span style={{ opacity: 0.5 }}>E.g: Non-Vegetarian</span>;
        }
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              py: selected.filter((value) => value !== '').length ? 0 : 1,
            }}>
            {selected
              .filter((value) => value !== '')
              .map((value: string) => (
                <Box key={value}>
                  <Chip
                    key={value}
                    clickable
                    sx={{
                      borderRadius: 2,
                      bgcolor: alpha(Colors.primary, 0.3),
                    }}
                    label={truncate(
                      subCategoriesData.find(
                        (category) => category._id === value,
                      )?.name,
                      { length: 20 },
                    )}
                  />
                  {/* TODO: @Layan Add the delete icon button and it's functionality */}
                </Box>
              ))}
          </Box>
        );
      }}>
      {subCategoriesData.length ? (
        subCategoriesData.map((category) => (
          <MenuItem key={category._id} value={category._id}>
            {category.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem sx={{ textTransform: 'capitalize' }} value={''}>
          No Sub Categories
        </MenuItem>
      )}
    </AppSelector>
  );
});

export default DishSubCategoriesSelector;
