import { yupResolver } from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import compact from 'lodash/compact';
import last from 'lodash/last';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { array, mixed, object, ObjectSchema, string } from 'yup';

import AppButton from '../../../components/AppButton';
import AppCurrencyInput from '../../../components/AppCurrencyInput';
import AppFileUploadInput from '../../../components/AppFileUploadInput';
import AppSelector from '../../../components/AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTextInput from '../../../components/AppTextInput';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectDishes } from '../../../redux/selectors/dishesSelectors';
import { checkMinImageDimensions, decimalCalculations } from '../../../utils';
import DishAddonsList from '../components/DishAddonsList';
import DishCategoriesSelector from '../components/DishCategoriesSelector';
import DishChoicesList from '../components/DishChoicesList';
import DishSubCategoriesSelector from '../components/DishSubCategoriesSelector';
import {
  getSingleDish,
  patchUpdateDish,
  resetDishesEditingDataState,
} from '../redux/dishesSlice';
import { createS3Image } from '../redux/imageSlice';
import { DISH_STATUS } from '../types/common.types';
import { UpdateDishRequestBodyDTO } from '../types/dishes.types';

interface IDishForm {
  name: string;
  categories: string[];
  subCategories?: (string | undefined)[];
  description?: string;
  status: DISH_STATUS;
  price: string;
  choices?: string[];
  addons?: string[];
  image?: File[] | null;
  receiptNotes?: string;
}

const dishValidationSchema: ObjectSchema<IDishForm> = object({
  name: string()
    .max(100, 'Character limit exceeded (100)')
    .required('Name is required'),
  categories: array(string().required())
    .min(1, 'Categories is required')
    .required('Categories is required'),
  subCategories: array(string().optional()),
  description: string().max(256, 'Character limit exceeded (256)').optional(),
  receiptNotes: string().optional(),
  status: string()
    .oneOf(Object.values(DISH_STATUS))
    .required('Status is required'),
  price: string().required('Price is required'),
  choices: array(string().required()),
  addons: array(string().required()),
  image: mixed<File[]>()
    .test(
      'is-image',
      'Invalid image file format, should be jpg, jpeg or png',
      (value) => {
        if (!value || value.length === 0) {
          return true; // Allow empty values
        }

        const fileArray: File[] = [];
        for (const element of value) {
          fileArray.push(element);
        }
        const fileExtensions = ['jpg', 'jpeg', 'png'];
        return fileArray.every((file) =>
          fileExtensions.includes(
            file.name.split('.').pop()?.toLowerCase() as string,
          ),
        );
      },
    )
    .test(
      'check-image-dimensions',
      'Image dimensions are incorrect',
      async (value) => {
        if (value?.length) {
          return (
            await Promise.all(
              value.map((file) =>
                checkMinImageDimensions(file, { width: 1024, height: 1024 }),
              ),
            )
          ).every((res) => res);
        }
        return true;
      },
    ),
});

const EditDishesView = () => {
  const [showChoices, setShowChoices] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const { openSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { loadingSingleDish, editingData } = useAppSelector(selectDishes);
  const categorySelectRef = useRef<HTMLDivElement | null>(null);

  const getSingleDishCallback = useCallback(
    async (pathParamId: string) => {
      const res = await dispatch(getSingleDish(pathParamId));
      if (res.meta.requestStatus === 'rejected') {
        navigate('/menu/items');
      }
    },
    [dispatch, navigate],
  );

  useLayoutEffect(() => {
    if (id) {
      getSingleDishCallback(id);
    }
  }, [id, getSingleDishCallback]);

  const { restaurant } = useAppSelector(selectAuth);

  const getImageUrl = useCallback(
    async (uploadableImage?: File) => {
      if (!uploadableImage) {
        return null;
      }

      const imageName = uploadableImage.name.split('.')[0];
      const currentImageName = last(editingData?.masterImage?.split('/'));

      if (imageName === currentImageName) {
        return editingData?.masterImage;
      }

      return (
        await dispatch(
          createS3Image({
            file: uploadableImage,
          }),
        )
      ).payload;
    },
    [dispatch, editingData?.masterImage],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<IDishForm>({
    resolver: yupResolver(dishValidationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      categories: [],
      subCategories: [],
      description: '',
      status: DISH_STATUS.AVAILABLE,
      price: '0',
      choices: [],
      addons: [],
      image: [],
      receiptNotes: '',
    },
  });

  const {
    errors: { categories: categoriesError },
  } = useFormState({ control, name: 'categories' });

  useEffect(() => {
    if (categoriesError) {
      categorySelectRef.current?.focus();
    }
  }, [categoriesError]);

  const onSubmit = async (values: IDishForm) => {
    const imageUrl = await getImageUrl(values.image?.[0]);

    if (id) {
      const payload: UpdateDishRequestBodyDTO = {
        name: values.name,
        categories: values.categories,
        subCategories: compact(values.subCategories),
        description: values.description,
        status: values.status,
        price: decimalCalculations(values.price.replace(/,/g, ''))
          .toCents()
          .toNumber(),
        choiceGroups: values.choices,
        addonsGroups: values.addons,
        masterImage: imageUrl,
        receiptNotes: values.receiptNotes,
      };

      const response = await dispatch(
        patchUpdateDish({ id: String(id), body: payload }),
      );

      if (response.meta.requestStatus === 'fulfilled') {
        openSnackbar(
          'The item has been updated successfully!',
          SNACKBARTYPE.SUCCESS,
        );
        navigate('/menu/items');
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getEditingData = async () => {
      if (editingData) {
        const imageUrl = editingData.masterImage;

        const imageData = imageUrl
          ? await axios
              .get<Blob>(imageUrl, {
                responseType: 'blob',
              })
              .then((res) => {
                return new File(
                  [res.data],
                  `${
                    imageUrl.split('/')[imageUrl.split('/').length - 1] ??
                    'image'
                  }.jpeg`,
                  {
                    type: 'image/jpeg',
                  },
                );
              })
          : null;

        setValue('name', editingData.name);
        setValue(
          'categories',
          editingData.categories.map((category) => category._id),
        );
        setValue(
          'subCategories',
          editingData.subCategories.map((sub) => sub._id),
        );
        setValue('description', editingData.description);
        setValue(
          'status',
          editingData.status ? editingData.status : DISH_STATUS.AVAILABLE,
        );
        setValue(
          'price',
          decimalCalculations(editingData.price).toDecimal().toString(),
        );
        setValue(
          'choices',
          editingData.choiceGroups.map((choice) => choice._id),
        );
        if (editingData.choiceGroups.length > 0) setShowChoices(true);

        setValue(
          'addons',
          editingData.addonsGroups.map((addon) => addon._id),
        );
        if (editingData.addonsGroups.length > 0) setShowAddons(true);

        setValue('image', imageData ? [imageData] : []);
        setValue('receiptNotes', editingData.receiptNotes);
      }
    };

    getEditingData();
  }, [editingData, setValue]);

  useEffect(() => {
    return () => {
      dispatch(resetDishesEditingDataState());
    };
  }, [dispatch]);

  const handleOnCancel = () => {
    navigate('/menu/items');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: loadingSingleDish ? 0.5 : 1,
      }}>
      {loadingSingleDish && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <HashScroll hashInput="#basic-details">
          <>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <AppTextInput
                  label="Item Name"
                  placeholder="E.g: Sandwich"
                  type="text"
                  {...field}
                  onBlur={field.onBlur}
                  error={errors.name?.message}
                  data-testid="Item Name"
                />
              )}
            />

            <Controller
              name="categories"
              control={control}
              render={({ field: { onChange, onBlur, value = [] } }) => (
                <DishCategoriesSelector
                  onChange={(e) => onChange([e.target.value])}
                  onBlur={onBlur}
                  value={value}
                  error={errors.categories?.message}
                  selectRef={categorySelectRef}
                />
              )}
            />

            <Controller
              name="subCategories"
              control={control}
              render={({ field: { onChange, onBlur, value = [] } }) => (
                <DishSubCategoriesSelector
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={errors.subCategories?.message}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <AppTextInput
                  showOptional
                  label="Description"
                  placeholder="E.g: A sandwich is a delicious, portable meal made by placing various ingredients, such as meats, vegetables, and condiments, between two slices of bread."
                  type="text"
                  multiline
                  {...field}
                  error={errors.description?.message}
                />
              )}
            />

            <Box display={'flex'} alignItems={'center'}>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <AppSelector
                    label="Status"
                    placeholder="Available"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.status?.message}>
                    <MenuItem value={DISH_STATUS.AVAILABLE}>Available</MenuItem>
                    <MenuItem value={DISH_STATUS.UNAVAILABLE}>
                      Unavailable
                    </MenuItem>
                    <MenuItem value={DISH_STATUS.UNAVAILABLE_FOR_TODAY}>
                      Sold Out For Today
                    </MenuItem>
                    <MenuItem value={DISH_STATUS.HIDDEN}>Hidden</MenuItem>
                  </AppSelector>
                )}
              />

              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <AppCurrencyInput
                    label="Price"
                    placeholder="0.00"
                    type="text"
                    prefix={restaurant?.posCurrencySymbol}
                    sx={{ ml: 2 }}
                    {...field}
                    error={errors.price?.message}
                    data-testid="price"
                  />
                )}
              />
            </Box>
          </>
        </HashScroll>

        {/* TODO: HiddenOn fields goes here */}

        <Divider sx={{ marginTop: '20px', marginBottom: '30px' }} />

        <HashScroll hashInput="#choices">
          <Accordion
            expanded={showChoices}
            onChange={(_, expanded) => setShowChoices(expanded)}
            elevation={0}
            sx={{
              marginLeft: -2,
              marginRight: -1,
              '&.Mui-expanded': {
                marginLeft: -2,
                marginRight: -1,
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="choices-bh-content"
              id="choices-bh-header">
              <Typography variant="h5" fontWeight={700}>
                Choices
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="choices"
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <DishChoicesList
                    onChange={(e, key) => {
                      const newValues = Array.isArray(value) ? [...value] : [];

                      if (e.target.checked) {
                        onChange([...newValues, key]);
                      } else {
                        onChange(newValues.filter((v) => v !== key));
                      }
                    }}
                    values={value}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </HashScroll>

        <HashScroll hashInput="#addons">
          <Accordion
            expanded={showAddons}
            onChange={(_, expanded) => setShowAddons(expanded)}
            elevation={0}
            sx={{
              marginLeft: -2,
              marginRight: -1,
              '&.Mui-expanded': {
                marginLeft: -2,
                marginRight: -1,
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="addons-bh-content"
              id="addons-bh-header">
              <Typography variant="h5" fontWeight={700}>
                Add-Ons
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="addons"
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <DishAddonsList
                    onChange={(e, key) => {
                      const newValues = Array.isArray(value) ? [...value] : [];

                      if (e.target.checked) {
                        onChange([...newValues, key]);
                      } else {
                        onChange(newValues.filter((v) => v !== key));
                      }
                    }}
                    values={value}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </HashScroll>
        <Box
          sx={{
            my: 2,
          }}>
          <Typography variant="h5" fontWeight={700} mb={2}>
            Receipt Notes
          </Typography>
          <Controller
            name="receiptNotes"
            control={control}
            render={({ field }) => (
              <AppTextInput
                label="Enter Notes"
                placeholder="E.g: Pick up at counter 1"
                type="text"
                {...field}
                onBlur={field.onBlur}
                error={errors.name?.message}
                data-testid="Receipt Notes"
              />
            )}
          />
        </Box>
        <Divider sx={{ my: 2 }} />

        <HashScroll hashInput="#upload-images">
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" fontWeight={700} mb={2}>
              Upload Image
            </Typography>
            <Controller
              name="image"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AppFileUploadInput
                  multiple={false}
                  value={value ?? []}
                  errors={error ? error.message : undefined}
                  onChange={(files) => onChange(files)}
                  additionalInstructions={[
                    'Clear and sharp images only',
                    'Max 5MB file size',
                    'Min 1024px x 1024px dimensions',
                  ]}
                />
              )}
            />
          </Box>
        </HashScroll>

        <Stack direction="row-reverse" sx={{ mt: 2, mb: 4 }}>
          <AppButton
            size="large"
            title="Save"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            sx={{ ml: 2 }}
          />

          <AppButton
            size="large"
            title="Cancel"
            color="secondary"
            variant="contained"
            onClick={handleOnCancel}
          />
        </Stack>
      </form>
    </Box>
  );
};

export default EditDishesView;
