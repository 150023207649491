import MenuItem from '@mui/material/MenuItem';
import { SelectProps } from '@mui/material';
import { useCallback, useEffect } from 'react';

import AppSelector from '../../../components/AppSelector';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectCategories } from '../../../redux/selectors/categoriesSelectors';
import { fetchCategoriesData } from '../redux/categoriesSlice';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import truncate from 'lodash/truncate';

type Props = Omit<SelectProps, 'error'> & {
  error?: string;
  selectRef?: React.RefObject<HTMLDivElement>;
};

const DishCategoriesSelector = (props: Props) => {
  const dispatch = useAppDispatch();
  const { categoriesData, refreshing } = useAppSelector(selectCategories);

  const fetchCategoriesDataCallback = useCallback(async () => {
    dispatch(setMenuPaginationSkip(undefined));
    dispatch(setMenuPaginationLimit(undefined));
    dispatch(setMenuPaginationSearchKey(''));
    dispatch(fetchCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    fetchCategoriesDataCallback();
  }, [fetchCategoriesDataCallback]);

  return (
    <AppSelector
      disabled={refreshing}
      label="Categories"
      placeholder="Categories"
      name="categories"
      onChange={props.onChange}
      error={props.error}
      value={props.value}
      onBlur={props.onBlur}
      data-testid="categories"
      selectRef={props.selectRef}
      displayEmpty
      renderSelectedArray={(selected) => {
        if (selected.length === 0) {
          return <span style={{ opacity: 0.5 }}>E.g: Mains</span>;
        }
        return (
          <span>
            {truncate(
              categoriesData.find((category) => category._id === selected[0])
                ?.name,
              { length: 20 },
            )}
          </span>
        );
      }}>
      {categoriesData.length ? (
        categoriesData.map((category) => (
          <MenuItem key={category._id} value={category._id}>
            {category.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem sx={{ textTransform: 'capitalize' }} value={''}>
          No Categories
        </MenuItem>
      )}
    </AppSelector>
  );
};

export default DishCategoriesSelector;
