import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { CREATE_DISHES_NAVIGATION_TABS } from '../constants';

const CreateDishesTabs = () => {
  const location = useLocation();

  const CREATE_DISH_TABS: IVerticalTabItem[] = useMemo(
    () => [
      {
        id: CREATE_DISHES_NAVIGATION_TABS.BASIC_DETAILS,
        label: 'Basic Details',
        Icon: RestaurantIcon,
        path: `${location.pathname}#basic-details`,
      },
      {
        id: CREATE_DISHES_NAVIGATION_TABS.CHOICES,
        label: 'Choices',
        Icon: RestaurantIcon,
        path: `${location.pathname}#choices`,
      },
      {
        id: CREATE_DISHES_NAVIGATION_TABS.ADDONS,
        label: 'Addons',
        Icon: RestaurantIcon,
        path: `${location.pathname}#addons`,
      },
      {
        id: CREATE_DISHES_NAVIGATION_TABS.UPLOAD_IMAGES,
        label: 'Upload Images',
        Icon: RestaurantIcon,
        path: `${location.pathname}#upload-images`,
      },
    ],
    [location],
  );

  const { currentTab, handleTabChange } = useTabNavigation(
    CREATE_DISH_TABS,
    CREATE_DISHES_NAVIGATION_TABS.BASIC_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={CREATE_DISH_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default CreateDishesTabs;
