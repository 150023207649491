import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import uniq from 'lodash/uniq';
import { DragEventHandler, useEffect, useState } from 'react';

import { Colors } from '../theme/colors';

const FILE_SIZE = 5000000; // 5MB
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

type AppFileUploadInputProps = {
  value: File[];
  errors?: string | string[];
  onChange: (files: File[]) => void;
  multiple?: boolean;
  fileSizeInBytes?: number;
  supportedFormats?: string[];
  additionalInstructions?: string[];
  receiptPage?: boolean;
};

const AppFileUploadInput = ({
  value,
  errors,
  multiple,
  fileSizeInBytes,
  supportedFormats,
  onChange,
  additionalInstructions,
  receiptPage,
}: AppFileUploadInputProps) => {
  const [files, setFiles] = useState<File[]>(value);

  useEffect(() => {
    setFiles(value);
  }, [value]);

  const handleFiles = (newFiles: File[]) => {
    if (multiple) {
      setFiles((prev) => {
        onChange([...prev, ...newFiles]);
        return [...prev, ...newFiles];
      });
    } else {
      setFiles(newFiles);
      onChange(newFiles);
    }
  };

  const onDragDrop: DragEventHandler<HTMLLabelElement> = (event) => {
    event.preventDefault();
    const dataTransferItems =
      event.dataTransfer.items || event.dataTransfer.files;
    const newFiles = Array.from(dataTransferItems)
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile())
      .filter((file): file is File => Boolean(file));
    handleFiles(newFiles);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = event.target.files;
    if (inputFiles?.length) {
      handleFiles(Array.from(inputFiles));
    }
  };

  const checkFileSize = (size: number) =>
    fileSizeInBytes ? size > fileSizeInBytes : size > FILE_SIZE;

  const checkFileType = (type: string) =>
    uniq([
      ...(supportedFormats?.length ? supportedFormats : SUPPORTED_FORMATS),
    ]).includes(type);

  const removeFile = (event: React.SyntheticEvent, file: File) => {
    event.preventDefault();
    setFiles((prev) => {
      const filteredImages = prev.filter((f) => f !== file);
      onChange(filteredImages);
      return filteredImages;
    });
  };

  const getErrors = () => {
    if (errors && typeof errors === 'string') {
      return errors;
    } else if (errors && Array.isArray(errors)) {
      return errors.join(',');
    } else {
      return null;
    }
  };

  const getAdditionalInstructions = () => {
    if (additionalInstructions && additionalInstructions.length > 0) {
      return additionalInstructions.join(' ・ ');
    }
  };

  return (
    <Box
      onDrop={onDragDrop}
      onDragOver={(e) => e.preventDefault()}
      component={'label'}
      sx={{
        position: 'relative',
        width: '100%',
        height: '200px',
        border: `2px dashed ${Colors.primary}`,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: alpha(Colors.primaryLight, 0.2),
        },
        transition: 'all 0.3s ease',
      }}>
      {files.length > 0 ? (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            onClick={(event) => event.preventDefault()}
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              mt: 1,
              mb: 1,
              zIndex: 1,
            }}>
            {files.map((file, i) => {
              if (checkFileSize(file.size) || !checkFileType(file.type)) {
                return (
                  <Tooltip
                    key={`${file.name}-${i}`}
                    title={
                      checkFileSize(file.size)
                        ? 'The file is too large'
                        : 'Invalid file type'
                    }
                    placement="bottom">
                    <Box
                      sx={{
                        position: 'relative',
                        background: Colors.primaryLight,
                        borderRadius: '10px',
                        width: '80px',
                        aspectRatio: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 1,
                      }}>
                      <Box
                        onClick={(event) => removeFile(event, file)}
                        sx={{
                          position: 'absolute',
                          top: -6,
                          right: -6,
                          width: '16px',
                          height: '16px',
                          aspectRatio: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          background: Colors.primary,
                          cursor: 'pointer',
                        }}>
                        <RemoveIcon
                          sx={{
                            color: Colors.white,
                            width: '10px',
                          }}
                        />
                      </Box>
                      <WarningIcon
                        sx={{
                          color: Colors.primary,
                          width: '40px',
                        }}
                      />
                    </Box>
                  </Tooltip>
                );
              } else {
                return (
                  <Box
                    key={`${file.name}-${i}`}
                    sx={{
                      position: 'relative',
                      background: Colors.primaryLight,
                      borderRadius: '10px',
                      width: receiptPage ? '200px' : '100px',
                      aspectRatio: receiptPage ? null : 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 1,
                    }}>
                    <Box
                      onClick={(event) => removeFile(event, file)}
                      sx={{
                        position: 'absolute',
                        top: -6,
                        right: -6,
                        width: '16px',
                        height: '16px',
                        aspectRatio: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        background: Colors.primary,
                        cursor: 'pointer',
                      }}>
                      <RemoveIcon
                        sx={{
                          color: Colors.white,
                          width: '10px',
                        }}
                      />
                    </Box>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  </Box>
                );
              }
            })}
          </Box>

          <Typography
            sx={{
              color: Colors.primary,
              fontWeight: 500,
              mb: 1,
            }}>
            {files.length} file{files.length > 1 && 's'} selected
          </Typography>

          <Box>
            <Typography
              sx={{
                color: getErrors()
                  ? Colors.failed
                  : alpha(Colors.fontColorSecondary, 0.8),
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {getErrors() ?? getAdditionalInstructions()}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}>
          <Box
            sx={{
              background: Colors.primaryLight,
              borderRadius: '10px',
              width: '40px',
              aspectRatio: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 1,
            }}>
            <InsertPhotoIcon
              sx={{
                color: Colors.primary,
              }}
            />
          </Box>

          <Typography
            sx={{
              color: Colors.primary,
              fontWeight: 500,
            }}>
            Drag and drop your image here
          </Typography>
          <Typography>or select it on the computer</Typography>
          <Typography
            sx={{
              color: alpha(Colors.fontColorSecondary, 0.8),
              fontWeight: 500,
              fontSize: '12px',
              justifyContent: 'center',
              wordWrap: 'break-word',
              textAlign: 'center',
            }}>
            {getAdditionalInstructions()}
          </Typography>
        </Box>
      )}
      <input
        accept={supportedFormats?.join(',') ?? SUPPORTED_FORMATS.join(',')}
        multiple={multiple}
        // Just to reset the input value if the user selects the same file
        onClick={(e) => (e.currentTarget.value = '')}
        onChange={onChangeHandler}
        style={{
          opacity: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
        type={'file'}
      />
    </Box>
  );
};

export default AppFileUploadInput;
