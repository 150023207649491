import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Brunch from '../../../assets/svgs/brunch.svg';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppEmptyTable from '../../../components/AppEmptyTable';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTable from '../../../components/AppTable';
import { IAppTableRow } from '../../../components/AppTable/types/table.types';
import {
  useAppDispatch,
  useAppSelector,
  usePaginationState,
} from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectDishes } from '../../../redux/selectors/dishesSelectors';
import { ACTION_KEYS, DISHES_COLUMNS } from '../constants';
import ViewLayout from '../hocs/ViewLayout';
import {
  deleteDish,
  fetchDishData,
  patchUpdateDish,
  updateDishSequenceNumbers,
} from '../redux/dishesSlice';
import {
  disableMenuPagination,
  setMenuPaginationLimit,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { DISH_STATUS, ENTITY_SORT } from '../types/common.types';
import { IDishesTable } from '../types/dishes.types';
import { getFormattedDishes } from '../utils/formatting.utils';

const DishesPage = () => {
  const { openConfirmationPopup } = useConfirmationPopup();
  const dispatch = useAppDispatch();
  const { restaurant } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { dishesData, refreshing, extras } = useAppSelector(selectDishes);

  const [isShowAll, setIsShowAll] = useState(false);

  const { onChange, pageIndex, pageSize, total } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const handleOnCreate = () => {
    navigate('/menu/items/create');
  };

  const fetchDishDataCallback = useCallback(async () => {
    if (isShowAll) {
      dispatch(disableMenuPagination());
    } else {
      dispatch(setMenuPaginationSkip(pageIndex * pageSize));
      dispatch(setMenuPaginationLimit(pageSize));
    }
    await dispatch(
      fetchDishData({ sort_by: 'sequenceNumber', sort_order: ENTITY_SORT.ASC }),
    );
  }, [dispatch, isShowAll, pageIndex, pageSize]);

  useEffect(() => {
    fetchDishDataCallback();
  }, [fetchDishDataCallback]);

  const formattedDishesData = useMemo(() => {
    return dishesData.map((dish) =>
      getFormattedDishes(dish, restaurant?.posCurrencySymbol ?? ''),
    );
  }, [dishesData, restaurant?.posCurrencySymbol]);

  const handleOnRowDrag = async (dragId: string, dropId: string) => {
    await dispatch(
      updateDishSequenceNumbers({
        dragId,
        dropId,
      }),
    );
  };

  const handlePreviousClick = () => onChange(pageIndex - 1);
  const handleNextClick = () => onChange(pageIndex + 1);

  const handleOnClickRow = useCallback(
    async (data: IAppTableRow<IDishesTable>) => {
      navigate(`/menu/items/edit/${data.key}`);
    },
    [navigate],
  );

  const debouncedHandleOnClickAction = useMemo(
    () =>
      debounce(
        async (key: string, data: IAppTableRow<IDishesTable>) => {
          const foundItem = dishesData.find((item) => item._id === data.key);

          switch (key) {
            case ACTION_KEYS.AVAILABLE: {
              const response = await dispatch(
                patchUpdateDish({
                  id: data.key,
                  body: {
                    status: DISH_STATUS.AVAILABLE,
                  },
                }),
              );

              if (response.meta.requestStatus === 'fulfilled') {
                openSnackbar(
                  'Dish status has been updated Successfully!',
                  SNACKBARTYPE.SUCCESS,
                );
              }
              await fetchDishDataCallback();
              break;
            }
            case ACTION_KEYS.DELETE: {
              openConfirmationPopup({
                color: 'error',
                heading: `Delete ${foundItem?.name}`,
                content: (
                  <Box>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {foundItem?.name}
                    </Typography>
                    {
                      ' will no longer be in the dishes and will be deleted permanently.'
                    }
                  </Box>
                ),
                confirmButtonTitle: 'Delete',
                onConfirm: async () => {
                  const response = await dispatch(deleteDish(data.key));
                  if (response.meta.requestStatus === 'fulfilled') {
                    openSnackbar(
                      'Dish has been deleted Successfully!',
                      SNACKBARTYPE.SUCCESS,
                    );
                  }
                  await fetchDishDataCallback();
                  onChange(0);
                },
              });
              break;
            }
            case ACTION_KEYS.HIDE: {
              openConfirmationPopup({
                heading: `Hide ${foundItem?.name}`,
                content: (
                  <Box>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {foundItem?.name}
                    </Typography>
                    {' will be hidden from the dishes.'}
                  </Box>
                ),
                confirmButtonTitle: 'Hide',
                onConfirm: async () => {
                  const response = await dispatch(
                    patchUpdateDish({
                      id: data.key,
                      body: {
                        status: DISH_STATUS.HIDDEN,
                      },
                    }),
                  );
                  if (response.meta.requestStatus === 'fulfilled') {
                    openSnackbar(
                      'Dish has been hidden Successfully!',
                      SNACKBARTYPE.SUCCESS,
                    );
                  }
                  await fetchDishDataCallback();
                },
              });
              break;
            }
            case ACTION_KEYS.UNAVAILABLE: {
              openConfirmationPopup({
                heading: `Unavailable ${foundItem?.name}`,
                content: (
                  <Box>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {foundItem?.name}
                    </Typography>
                    {
                      ' will be unavailable and will no longer be listed in the dishes.'
                    }
                  </Box>
                ),
                confirmButtonTitle: 'Unavailable',
                onConfirm: async () => {
                  const response = await dispatch(
                    patchUpdateDish({
                      id: data.key,
                      body: {
                        status: DISH_STATUS.UNAVAILABLE,
                      },
                    }),
                  );
                  if (response.meta.requestStatus === 'fulfilled') {
                    openSnackbar(
                      'Dish status has been updated to Unavailable Successfully!',
                      SNACKBARTYPE.SUCCESS,
                    );
                  }
                  await fetchDishDataCallback();
                },
              });
              break;
            }
            case ACTION_KEYS.EDIT:
              handleOnClickRow(data);
              break;
            default:
              break;
          }
        },
        500,
        { leading: true, trailing: false },
      ),
    [
      dispatch,
      dishesData,
      fetchDishDataCallback,
      openSnackbar,
      openConfirmationPopup,
      handleOnClickRow,
      onChange,
    ],
  );

  const handleShowAllResultsClick = () => {
    if (isShowAll) {
      dispatch(disableMenuPagination());
    }
    setIsShowAll((prevState) => !prevState);
  };

  return (
    <ViewLayout
      title="Items"
      onClickCreate={handleOnCreate}
      buttonText="Create Item"
      hideButton={formattedDishesData.length === 0}>
      <AppTable
        refreshing={refreshing}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
        enablePagination={false}
        pagination={{
          total: total,
          pageIndex: pageIndex,
          pageSize: pageSize,
          showAllStatus: isShowAll,
        }}
        columns={DISHES_COLUMNS}
        rows={formattedDishesData}
        enableRowActions
        onClickActions={debouncedHandleOnClickAction}
        onClickRow={handleOnClickRow}
        emptyComponent={
          <AppEmptyTable
            title="No Items Added"
            icon={<img src={Brunch} />}
            primaryButton={{
              title: 'Create Item',
              startIcon: <AddIcon />,
              onClick: handleOnCreate,
            }}
          />
        }
        onDragDropChange={handleOnRowDrag}
        enableShowAllResultsButton
        handleShowAllResultsClick={handleShowAllResultsClick}
      />
    </ViewLayout>
  );
};

export default DishesPage;
