import TabPageLayout from '../../../hocs/AppPageLayout';
import EditDishesTabs from '../components/EditDishesTabs';
import EditDishesView from '../views/EditDishesView';

const breadCrumbs = [
  {
    title: 'Menu',
    link: '/menu',
  },
  {
    title: 'Items',
    link: `/menu/items`,
  },
  {
    title: 'Edit Items',
    link: `/menu/items/edit`,
  },
];

const EditDishesPage = () => {
  return (
    <TabPageLayout
      title="Edit Item"
      TabComponent={EditDishesTabs}
      ContentComponent={EditDishesView}
      breadCrumbs={breadCrumbs}
      titleVariant="h5"
    />
  );
};

export default EditDishesPage;
