import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import AppList from '../../../components/AppList';
import { APP_LIST_COLUMN_TYPES } from '../../../components/AppList/types/list.types';
import AppSwitch from '../../../components/AppSwitch';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAddOnGroups } from '../../../redux/selectors/addOnGroupsSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import { DISH_CREATION_ADDON_GROUPS_LIST_COLUMNS } from '../constants';
import { fetchAddOnsData } from '../redux/addOnGroupSlice';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { IAddOn } from '../types/add-on-groups.types';
import { getFormattedDishCreationAddonGroups } from '../utils/formatting.utils';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
  values?: string[];
};

const DishAddonsList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { addOnGroupsData, refreshing } = useAppSelector(selectAddOnGroups);
  const { restaurant } = useAppSelector(selectAuth);

  const fetchAddOnsDataCallback = useCallback(async () => {
    dispatch(setMenuPaginationSkip(undefined));
    dispatch(setMenuPaginationLimit(undefined));
    dispatch(setMenuPaginationSearchKey(''));
    await dispatch(fetchAddOnsData());
  }, [dispatch]);

  useEffect(() => {
    fetchAddOnsDataCallback();
  }, [fetchAddOnsDataCallback]);

  const formattedDishAddonGroupsData = useMemo(() => {
    return addOnGroupsData.map(getFormattedDishCreationAddonGroups);
  }, [addOnGroupsData]);

  return (
    <AppList
      columns={DISH_CREATION_ADDON_GROUPS_LIST_COLUMNS}
      rows={formattedDishAddonGroupsData}
      hideHeader
      refreshing={refreshing}
      enableRowActions
      renderDetailPanel={(value) => {
        const addons = value.addons as {
          type: APP_LIST_COLUMN_TYPES.LIST;
          data: { value: IAddOn[] };
        };

        return (
          <Box
            sx={{
              display: 'grid',
              padding: '0.2rem',
              gridTemplateColumns: '1fr 1fr 1fr',
              width: '100%',
              gap: '0.6rem',
            }}>
            {addons.data.value.map((addon) => (
              <Box
                key={addon._id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography variant="body2" textTransform={'capitalize'}>
                  {addon.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: Colors.fontColorSecondary }}>
                  +
                  {decimalCalculations(addon.price ?? 0)
                    .toDecimal()
                    .toMoney(restaurant?.posCurrencySymbol)}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      }}
      renderRowActions={(value) => {
        return (
          <AppSwitch
            id={value.key}
            name={'addons'}
            value={value.key}
            checked={
              Array.isArray(props.values)
                ? props.values.includes(value.key)
                : false
            }
            onChange={(e) => props.onChange(e, value.key)}
          />
        );
      }}
    />
  );
};

export default DishAddonsList;
