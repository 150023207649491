import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  CreateImageRequestBodyDTO,
  PresignedUrlFields,
} from '../types/image.types';
import * as imageTypes from './imageActionTypes';

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const createS3Image = createAppAsyncThunk<
  string,
  CreateImageRequestBodyDTO
>(imageTypes.CREATE_S3_IMAGE, async (body, { rejectWithValue }) => {
  try {
    const presignedUrlResponse = await Api.get<
      CommonResponseDTO<{
        key: string;
        fields: PresignedUrlFields;
        url: string;
      }>
    >(URLS.IMAGES.CREATE_PRESIGNED_URL);
    const presignedUrlData = presignedUrlResponse.data.data;
    await axios.post(
      presignedUrlData.url,
      {
        ...presignedUrlData.fields,
        file: body.file,
        acl: 'public-read',
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return `${presignedUrlData.url}${presignedUrlData.key}`;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});
