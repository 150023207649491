import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import InputAdornment from '@mui/material/InputAdornment';
import { SxProps, Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ForwardedRef, forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { Colors } from '../theme/colors';

type AppCurrencyInputProps = {
  icon?: React.ReactNode;
  iconDisabled?: boolean;
  iconPosition?: 'start' | 'end';
  onClickIcon?: () => void;
  labelRightComponent?: React.ReactNode;
  touched?: boolean;
  label?: string;
  error?: string;
  showOptional?: boolean;
  prefix?: string;
  suffix?: string;
  textSx?: SxProps<Theme>;
  sx: SxProps<Theme>;
} & NumericFormatProps &
  Omit<TextFieldProps, 'error'>;

const AppCurrencyInput = forwardRef(
  (
    {
      icon,
      iconDisabled,
      onClickIcon,
      iconPosition = 'end',
      label,
      labelRightComponent,
      error,
      touched,
      showOptional,
      textSx = {},
      sx = {},
      disabled,
      value,
      prefix,
      suffix,
      ...props
    }: AppCurrencyInputProps,
    ref: ForwardedRef<HTMLInputElement | undefined>,
  ) => {
    return (
      <Box
        sx={{
          width: '100%',
          ...sx,
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px',
          }}>
          {label && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                opacity: disabled ? 0.5 : 1,
              }}>
              {label}
              {showOptional ? (
                <span
                  style={{
                    fontStyle: 'italic',
                    marginLeft: '4px',
                    marginRight: '4px',
                    fontWeight: 400,
                  }}>
                  - Optional
                </span>
              ) : null}
            </Typography>
          )}
          {labelRightComponent}
        </Box>
        <NumericFormat
          {...props}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            return (
              !formattedValue.startsWith('-') &&
              (floatValue === undefined || floatValue >= 0)
            );
          }}
          disabled={disabled}
          value={value as never}
          customInput={TextField}
          decimalScale={2}
          hiddenLabel={!label}
          error={touched && !!error}
          decimalSeparator="."
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          inputRef={ref}
          sx={{
            width: '100%',
            '& fieldset': {
              borderRadius: '6px',
            },
            '& .MuiInputBase-input': {
              height: '1em',
            },
            ...textSx,
          }}
          InputProps={
            icon
              ? iconPosition === 'start'
                ? {
                    startAdornment: (
                      <InputAdornment
                        position={iconPosition}
                        onClick={onClickIcon}
                        disablePointerEvents={iconDisabled}>
                        {icon}
                      </InputAdornment>
                    ),
                  }
                : iconPosition === 'end'
                  ? {
                      endAdornment: (
                        <InputAdornment
                          position={iconPosition}
                          onClick={onClickIcon}
                          disablePointerEvents={iconDisabled}>
                          {icon}
                        </InputAdornment>
                      ),
                    }
                  : {}
              : prefix
                ? {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        disablePointerEvents={true}>
                        {prefix}
                      </InputAdornment>
                    ),
                  }
                : suffix
                  ? {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}>
                          {suffix}
                        </InputAdornment>
                      ),
                    }
                  : {}
          }
        />
        <Box
          sx={{
            height: '25px',
            marginTop: '-2px',
          }}>
          <Fade in={touched && !!error}>
            {touched && !!error ? (
              <Typography
                variant="caption"
                sx={{
                  color: Colors.error,
                  marginLeft: '10px',
                  paddingTop: '5px',
                  display: 'flex',
                  lineHeight: 'normal',
                }}>
                {error}
              </Typography>
            ) : (
              <Box />
            )}
          </Fade>
        </Box>
      </Box>
    );
  },
);

export default AppCurrencyInput;
