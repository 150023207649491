import TabPageLayout from '../../../hocs/AppPageLayout';
import CreateDishesTabs from '../components/CreateDishesTabs';
import CreateDishView from '../views/CreateDishView';

const breadCrumbs = [
  {
    title: 'Menu',
    link: '/menu',
  },
  {
    title: 'Items',
    link: `/menu/items`,
  },
  {
    title: 'Create Items',
    link: `/menu/items/create`,
  },
];

const CreateDishesPage = () => {
  return (
    <TabPageLayout
      title="Create Items"
      TabComponent={CreateDishesTabs}
      ContentComponent={CreateDishView}
      breadCrumbs={breadCrumbs}
      titleVariant="h5"
    />
  );
};

export default CreateDishesPage;
