import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import AppList from '../../../components/AppList';
import { APP_LIST_COLUMN_TYPES } from '../../../components/AppList/types/list.types';
import AppSwitch from '../../../components/AppSwitch';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectChoiceGroups } from '../../../redux/selectors/choiceGroupsSelectors';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import { DISH_CREATION_CHOICE_GROUPS_LIST_COLUMNS } from '../constants';
import { fetchChoiceGroupData } from '../redux/choiceGroupSlice';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { IChoice } from '../types/choice-groups.types';
import { getFormattedDishCreationChoiceGroups } from '../utils/formatting.utils';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
  values?: string[];
};

const DishChoicesList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { choiceGroupsData, refreshing } = useAppSelector(selectChoiceGroups);
  const { restaurant } = useAppSelector(selectAuth);

  const fetchChoiceGroupDataCallback = useCallback(async () => {
    dispatch(setMenuPaginationSkip(undefined));
    dispatch(setMenuPaginationLimit(undefined));
    dispatch(setMenuPaginationSearchKey(''));
    await dispatch(fetchChoiceGroupData());
  }, [dispatch]);

  useEffect(() => {
    fetchChoiceGroupDataCallback();
  }, [fetchChoiceGroupDataCallback]);

  const formattedDishChoiceGroupsData = useMemo(() => {
    return choiceGroupsData.map(getFormattedDishCreationChoiceGroups);
  }, [choiceGroupsData]);

  return (
    <AppList
      columns={DISH_CREATION_CHOICE_GROUPS_LIST_COLUMNS}
      rows={formattedDishChoiceGroupsData}
      hideHeader
      refreshing={refreshing}
      enableRowActions
      renderDetailPanel={(value) => {
        const choices = value.choices as {
          type: APP_LIST_COLUMN_TYPES.LIST;
          data: { value: IChoice[] };
        };

        return (
          <Box
            sx={{
              display: 'grid',
              padding: '0.2rem',
              gridTemplateColumns: '1fr 1fr 1fr',
              width: '100%',
              gap: '0.6rem',
            }}>
            {choices.data.value.map((choice) => (
              <Box
                key={choice._id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography variant="body2" textTransform={'capitalize'}>
                  {choice.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: Colors.fontColorSecondary }}>
                  {decimalCalculations(choice.price ?? 0)
                    .toDecimal()
                    .toMoney(restaurant?.posCurrencySymbol)}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      }}
      renderRowActions={(value) => {
        return (
          <AppSwitch
            id={value.key}
            name={'choices'}
            value={value.key}
            checked={
              Array.isArray(props.values)
                ? props.values.includes(value.key)
                : false
            }
            onChange={(e) => props.onChange(e, value.key)}
          />
        );
      }}
    />
  );
};

export default DishChoicesList;
